<template>
  <div>
    <article>
      <h3 class="center">用户作品发布须知</h3>
      <section>
        <h5>尊敬的用户:</h5>
        <p>
          欢迎您使用{{ appName }}APP！根据我国有关法律法规要求，您若通过{{
            appName
          }}APP上传作品（包括但不限于文字、图片、视频、音频等各种形式的内容及其中包含的音乐、声音、台词、视觉设计等所有组成部分），您应确认该等作品属于您的原创或已获合法授权（包含转授权）。若您为原创作者，在制作音乐作品的过程中所用到的软件和音频信息，包括但不限于软件、采样、录音、音源、插件及音色库，应为经合法授权的正版软件，且在授权范围内使用，不存在侵犯任何第三方权利的情况。
        </p>
        <p>
          请您知悉，若您在使用{{
            appName
          }}APP上传的文字、音频、图片、视频、链接等存在侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益时，{{
            appName
          }}有权在收到权利方或者相关方通知的情况下删除该涉嫌侵权内容；若存在权益受侵害方主张权利，您需要自行承当相应的法律责任
        </p>
        <p>
          快音APP的部分产品和/或服务涉及基于生成合成算法的服务（例如AI写歌），您理解并保证：您不得以任何方式删除、篡改、隐匿快音的人工智能生成标识或深度合成内容显著标识；您对外发布或传播算法输出内容前，您应当主动核查输出内容的真实性、准确性，避免传播虚假信息，并以显著方式标明该输出内容系由人工智能生成，以向公众提示内容合成的情况。
        </p>
      </section>
      <section class="sign">
        <p>成都开心音符科技有限公司</p>
        <p>2023年11月【09】日</p>
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    name: 'UserReleaseCommitment',
    components: {},
    props: {},
    data() {
      return {
        appName: '',
        appNameMap: new Map([
          ['kuaiyin', { appName: '快音' }],
          ['kuaiyintingge', { appName: '快音听歌版' }],
          ['kuaiyinjisuban', { appName: '快音极速版' }],
          ['kuaiyinyue', { appName: '快音悦' }],
          ['kuaiyinfm', { appName: '快音FM' }],
        ]),
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      document.title = '用户作品发布须知';
      let app_name = 'kuaiyin';

      if (this.$route.query.app_name) {
        app_name = this.$route.query.app_name;
      } else if (window.bridge && window.bridge.getAppName) {
        app_name = window.bridge.getAppName() || 'kuaiyin';
      }

      this.appName = this.appNameMap.get(app_name).appName;
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  article {
    margin: 0 4%;
  }
  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
    text-indent: 2em;
  }
  .center {
    text-align: center;
  }
  .sign {
    margin: 50px 0;
    text-align: right;
  }
</style>
